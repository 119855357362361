import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./gallery.scss";
import React, { useState } from "react";
import { Swiper as SwiperType } from "swiper";

interface GalleryProps {
    images: string[];
    captions: string[];
}

const Gallery: React.FC<GalleryProps> = ({ images, captions }) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [swiper, setSwiper] = useState<SwiperType | null>(null);

    const handleChange = () => {
        if (swiper) {
            setActiveIndex(swiper.realIndex);
        }
    }
    
    return (
        <SwiperComponent
            id="gallery"
            onSwiper={(e : SwiperType | null) => setSwiper(e)}
            pagination={{
                el: ".pag",
            }}
            navigation={{
                nextEl: ".nav-next",
                prevEl: ".nav-prev",
            }}           
            modules={[Pagination, Navigation]}
            loop={true}
            onSlideChange={() => handleChange() }
        >
            {images.map((image, index) => (
                <SwiperSlide key={index}>
                    <div className="box"></div>
                    <div className="box"></div>
                    <div className="box"></div>
                    <div className="box"></div>
                    <div
                        className="img-container"
                        style={{ backgroundImage: `url(${image})` }}
                    >
                        { (index === 6 ) && (
                            <p className="cgi">
                                CGI, indicitive only
                            </p>    
                        )}
                        </div>
                </SwiperSlide>
            ))}
            <div className="contact-wrapper">
                <div className="corner-box"></div>
                <div className="corner-box"></div>
                <div className="corner-box"></div>
                <div className="corner-box"></div>
                <div className="contact">
                    <div className="corner-box"></div>
                    <div className="corner-box"></div>
                    <div className="corner-box"></div>
                    <div className="corner-box"></div>

                    <h3>{captions[activeIndex].toLocaleUpperCase()}</h3>
                </div>
            </div>
            <div className="navi-wrapper">
                <div className="nav-prev">PREV</div>
                <div className="pag"></div>
                <div className="nav-next">NEXT</div>
            </div>
        </SwiperComponent>
    );
};

export default Gallery;
